@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700;800;900&display=swap");

.ag-theme-material {
  /* use theme parameters where possible */
  --ag-header-background-color: #ecf6fd;
  --ag-header-foreground-color: #333333;
  --ag-header-cell-moving-background-color: #ecf6fd;
  --ag-header-cell-hover-background-color: #ecf6fd;
  --ag-range-selection-border-color: rgba(0, 0, 0, 0);
  --ag-selected-row-background-color: #ecf6fd;
  /* --ag-toggle-button-on-background-color: #eff2ff !important;
  --ag-toggle-button-on-border-color: #eff2ff; */
  /* Font */
  font-family: "Kanit", sans-serif;
  font-size: 14px;
}

.ag-theme-material .ag-header-row:not(:first-child) .ag-header-cell {
  background-color: white;
}

.ag-theme-material .ag-header-row:not(:first-child) .ag-header-cell:hover {
  background-color: #f2f2f2 !important;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #2167d3 !important;
}

.ag-theme-material .ag-radio-button-input-wrapper.ag-checked::after {
  color: #2167d3 !important;
}

.ag-theme-material .ag-tab-selected {
  border-bottom-color: #ecf6fd;
}

.ag-icon {
  color: #bdbdbd !important;
}

.ag-theme-material
  .ag-ltr
  .ag-side-bar-right
  .ag-selected
  .ag-side-button-button {
  border-bottom-color: #ecf6fd;
}

/* border color of range selecd cells */
.ag-theme-material
  .ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-left {
  border-left-color: #ecf6fd;
}
.ag-theme-material
  .ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-top {
  border-top-color: #ecf6fd;
}
.ag-theme-material
  .ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-right {
  border-right-color: #ecf6fd;
}
.ag-theme-material
  .ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-bottom {
  border-bottom-color: #ecf6fd;
}

/* .ag-theme-material input[class^="ag-"][type="text"] {
  border-bottom-color: #d6cfcf;
} */

.ag-theme-material input[class^="ag-"][type="text"]:focus {
  border-bottom-color: #bdbdbd;
}

.ag-theme-material .ag-header-row {
  font-size: 14px;
}

/* .ag-theme-material .ag-root-wrapper {
  border-radius: 15px !important;
  border: 1px solid #d6cfcf;
}

.ag-theme-material .ag-header {
  border: unset !important;
} */

.ag-theme-material .ag-details-row {
  padding: 20px;
  background-color: #f7faf8;
}

.ag-cell-wrapper {
  display: flex;
  align-items: center;
}

.small-padding {
  padding: 0 8px !important;
}

.ag-theme-material .ag-ltr .ag-row-drag {
  margin-right: 12px;
}

.center {
  display: flex;
  justify-content: center !important;
  text-align: center !important;
  column-gap: 2px;
}

.color-red {
  color: #f44336;
}

.pdf {
  font-size: 12px;
  padding: 0 !important;
}

.text-center * {
  justify-content: center !important;
}

.pdf-info {
  font-size: 12px;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-material .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-material .ag-layout-print .ag-center-cols-clipper,
.ag-theme-material .ag-layout-print .ag-center-cols-container {
  min-height: 50px;
}

.double-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: "center";
}

.ag-cell-value {
  display: flex;
}

.ag-end-header .ag-header-cell-label {
  justify-content: end;
}

.ag-center-header .ag-header-cell-label {
  justify-content: center;
}
